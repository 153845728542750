@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    overflow-y: overlay;
}

body {
    background-color: #0D1021;
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 700px) {
    body {
        background-color: #141539;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: unset;
    text-decoration: none;
}

/* width */
::-webkit-scrollbar {
    position: absolute;
    width: 0px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
